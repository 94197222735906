import React from 'react';
import { Link } from 'react-router-dom';
import './SideBar.css';
import { FaUser } from 'react-icons/fa';
import { MdOutlineRateReview } from 'react-icons/md';
import { RiAdminFill } from 'react-icons/ri';
import { FaUserShield } from 'react-icons/fa';
import { HiOutlineHome } from 'react-icons/hi';
import { ImPhone } from 'react-icons/im';
import { FaSignInAlt, FaUserPlus } from 'react-icons/fa';  // New imports for Login and Signup
import { useNavigate } from 'react-router-dom';

const SideBar = () => {
    const navigate = useNavigate(); // Use useNavigate hook for navigation

    const handleSignUpClick = () => {
        navigate('/sign-up'); // Navigate to the signup page
    };

    const handleSignInClick = () => {
        navigate('/login'); // Navigate to the signin page
    };

    return (
        <div>
            <div className='homeSidebar'>
                <nav className="overflow-auto forRightScroll">
                    <h6>
                        <Link to='/login' className="text-dark">
                            <FaSignInAlt /> Login
                        </Link>
                    </h6>
                    <h6>
                        <Link to='/sign-up' className="text-dark">
                            <FaUserPlus /> Signup
                        </Link>
                    </h6>
                    <h6>
                        <Link to='/support' className="text-dark">
                            <ImPhone /> Support
                        </Link>
                    </h6>
                </nav>
            </div>
        </div>
    );
};

export default SideBar;
